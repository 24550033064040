var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetInformationStyled', {
    attrs: {
      "isMobile": _vm.isMobile
    }
  }, [_vm.asset ? [_c('IconStyled', [_vm.iconName ? _c('TalpaIcon', {
    staticClass: "asset-icon",
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.iconName
    }
  }) : _vm._e()], 1), _c('AssetDetailsStyled', [_c('table', {
    staticClass: "column"
  }, [_c('tr', {
    staticClass: "row"
  }, [_c('td', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('model', 1)))]), _c('td', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.asset.model.name))])]), _vm.asset.customerId ? _c('tr', {
    staticClass: "row"
  }, [_c('td', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('technicalID', 1)))]), _c('td', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.asset.customerId))])]) : _vm._e(), _c('tr', {
    staticClass: "row"
  }, [_c('td', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('lastUpdate', 1)))]), _c('td', {
    staticClass: "value"
  }, [_c('TimeAgo', {
    attrs: {
      "time": _vm.asset.lastLog
    }
  })], 1)]), _c('tr', {
    staticClass: "row"
  }, [_c('td', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('totalMachineHours', 1)))]), _c('td', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.engineHours))])]), _vm.asset.lastPosition && _vm.asset.lastPosition.geofence ? _c('tr', {
    staticClass: "row"
  }, [_c('td', {
    staticClass: "label"
  }, [_vm._v(_vm._s(_vm.$tc('geofence', 1)))]), _c('td', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.asset.lastPosition ? _vm.asset.lastPosition.geofence : ''))])]) : _vm._e()])]), _c('SignalLinkStyled', [_vm.hasSignalReadPermission ? _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'signals',
        query: {
          assetId: _vm.asset.id
        }
      }
    }
  }, [!_vm.hasMaintenanceAppPermission && !_vm.hasSDAppPermission ? _c('ActivityIcon') : _c('SelectButtonStyled', [_vm._v(" " + _vm._s(_vm.$t('actions.goToSignals')) + " ")])], 1) : _vm._e(), _vm.hasMaintenanceAppPermission || _vm.hasSDAppPermission ? _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: _vm.routeName,
        params: {
          id: _vm.asset.id
        }
      }
    }
  }, [_c('SelectButtonStyled', [_vm._v(" " + _vm._s(_vm.$t('actions.goToAssetHealthOverview')) + " ")])], 1) : _vm._e()], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }