<template>
  <AssetInformationStyled :isMobile="isMobile">
    <template v-if="asset">
      <IconStyled>
        <TalpaIcon v-if="iconName" class="asset-icon" :scope="'AssetTypes'" :name="iconName" />
      </IconStyled>
      <AssetDetailsStyled>
        <table class="column">
          <tr class="row">
            <td class="label">{{ $tc('model', 1) }}</td>
            <td class="value">{{ asset.model.name }}</td>
          </tr>
          <tr class="row" v-if="asset.customerId">
            <td class="label">{{ $tc('technicalID', 1) }}</td>
            <td class="value">{{ asset.customerId }}</td>
          </tr>
          <tr class="row">
            <td class="label">{{ $tc('lastUpdate', 1) }}</td>
            <td class="value">
              <TimeAgo :time="asset.lastLog" />
            </td>
          </tr>
          <tr class="row">
            <td class="label">{{ $tc('totalMachineHours', 1) }}</td>
            <td class="value">{{ engineHours }}</td>
          </tr>
          <tr class="row" v-if="asset.lastPosition && asset.lastPosition.geofence">
            <td class="label">{{ $tc('geofence', 1) }}</td>
            <td class="value">{{ asset.lastPosition ? asset.lastPosition.geofence : '' }}</td>
          </tr>
        </table>
      </AssetDetailsStyled>
      <SignalLinkStyled>
        <router-link v-if="hasSignalReadPermission" class="link" :to="{ name: 'signals', query: { assetId: asset.id } }">
          <ActivityIcon v-if="!hasMaintenanceAppPermission && !hasSDAppPermission" />
          <SelectButtonStyled v-else> {{ $t('actions.goToSignals') }} </SelectButtonStyled>
        </router-link>
        <router-link
          v-if="hasMaintenanceAppPermission || hasSDAppPermission"
          class="link"
          :to="{ name: routeName, params: { id: asset.id } }"
        >
          <SelectButtonStyled> {{ $t('actions.goToAssetHealthOverview') }} </SelectButtonStyled>
        </router-link>
      </SignalLinkStyled>
    </template>
  </AssetInformationStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import localesMixin from '@/mixins/locales'
import { TalpaIcon, TimeAgo } from '@common/components'
import { flexStartCenter } from '@styles/mixins'
import { ActivityIcon } from 'vue-feather-icons'
import units from '@/utils/units'
import { buttonReset } from '@styles/mixins'

const AssetInformationStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 0.5rem;
  @media (max-width: 535px) {
    flex-direction: column;
  }
`
const AssetDetailsStyled = styled('div')`
  ${flexStartCenter}
  flex-basis: 100%;
  .column {
    display: table;
    width: 100%;
    .row {
      margin-top: 0.5rem;
      .label {
        text-align: left;
        color: ${({ theme }) => (theme.isDark ? theme.colors.muted : theme.colors.dimGrey)};
        width: 1%;
        white-space: nowrap;
        padding-right: 20px;
        @media (max-width: 768px) {
          word-break: break-word;
        }
      }
      .value {
        margin-left: 0.5rem;
        flex-grow: 1;
        word-break: break-word;
      }
    }
  }
`
const SignalLinkStyled = styled('div')`
  .link {
    padding: 0.25rem;
    .feather {
      border-radius: 0.5rem;
      background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
      padding: 0.25rem;
      width: 16px;
      height: 16px;
    }
  }
`
const IconStyled = styled('div')`
  display: flex;
  align-items: center;
  .asset-icon {
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg.icon {
      width: max-content;
      height: max-content;
      padding: 10px;
    }
  }
`

const SelectButtonStyled = styled('button')`
  ${buttonReset}
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => p.theme.colors.white};
  border-radius: 3px;
  background: ${p => p.theme.colors.primary};
  height: 2.8rem;
  &:hover:enabled {
    color: ${p => p.theme.colors.white};
    background: ${p => p.theme.colors.primary};
  }
  width: 12rem;
`

export default {
  name: 'AssetInformationCard',
  inject: ['uiSettings'],
  mixins: [TimeframeMixin, resizeMixin, localesMixin],
  props: {
    asset: {
      type: Object,
      required: false,
    },
    hasSignalReadPermission: {
      type: Object,
      required: false,
    },
    hasMaintenanceAppPermission: {
      type: Boolean,
      required: false,
    },
    hasSDAppPermission: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    AssetInformationStyled,
    AssetDetailsStyled,
    SignalLinkStyled,
    TimeAgo,
    TalpaIcon,
    IconStyled,
    SelectButtonStyled,
    ActivityIcon,
  },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    iconName() {
      return get(this.asset, 'type.name', '').split(' ').join('')
    },
    engineHours() {
      let machineHours = '-'
      if (this.asset?.lastPosition) {
        machineHours =
          this.asset?.lastPosition?.totalMachineHours !== 0
            ? units(
                this.asset?.lastPosition?.totalMachineHours,
                '',
                '',
                2,
                false,
                true,
                false,
                this.thousandsSeperator,
                this.decimalSeperator,
                true,
              )
            : '-'
      }
      return machineHours
    },

    routeName() {
      let routeName = 'AssetHealthOverview'
      if (this.hasMaintenanceAppPermission && this.hasSDAppPermission) {
        const selectedApp = get(this.uiSettings, 'defaultApp', 'MAINTENANCE_APP')
        routeName = selectedApp === 'SD_APP' ? 'ServiceDealerAssetHealthOverview' : 'AssetHealthOverview'
      } else if (!this.hasMaintenanceAppPermission && this.hasSDAppPermission) {
        routeName = 'ServiceDealerAssetHealthOverview'
      }
      return routeName
    },
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        this.isMobile = size < 700 && size >= 96
      })
    },
  },
}
</script>
